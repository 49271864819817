import React, { memo, useState } from 'react'
import { Tooltip } from 'antd'
import { getText } from '../../../../../lang'
import SVGRemindMe from '../../../../../icons/SVG/SVGRemindMe'
import DateTimePickerModal from '../../../../../components/api/DateTimePickerModal'
import MatButton from '../../../../../components/MatButton'
import MatModal from '../../../../../components/MatModal'
import MatRow from '../../../../../components/MatRow'

const RemindMePicker = (props) => {
  const {
    conversation,
    addToList,
    unarchiveConversation,
    unAssignConversation,
    mobileDeskSize,
    onCloseMobileDesk,
  } = props

  const [visible, setVisible] = useState(false)
  const [remindedDay, setRemindedDay] = useState('')
  const title = (
    <MatRow style={{ justifyContent: 'center' }}>
      <SVGRemindMe
        style={{ marginRight: '10px' }}
        color={conversation.isNew ? '#a3a6be' : '#0e0e0e'}
        className={'icon_size_reminder'}
      />
      {getText('WORD_REMINDER')}
    </MatRow>
  )

  const remindMeOptions = [
    {
      label: getText('WORD_LATER_TODAY'),
      value: 'later_today',
    },
    {
      label: getText('WORD_TOMORROW'),
      value: 'tomorrow',
    },
    {
      label: getText('WORD_NEXT_WEEK'),
      value: 'next_week',
    },
    {
      label: getText('WORD_PICK_TIME_AND_DAY'),
      value: 'pick_time_and_day',
    },
  ]

  return (
    <MatModal
      width={335}
      className={'message_date_picker_dropdown'}
      triggerClassName={'footerAction'}
      dataIndex={'reminder'}
      title={title}
      trigger={
        <Tooltip
          overlayStyle={{ zIndex: mobileDeskSize ? '-1000' : '10001' }}
          destroyTooltipOnHide={true}
          trigger={['hover', 'click']}
          title={getText('WORD_REMINDER')}
        >
          <MatButton
            icon={
              mobileDeskSize ? <SVGRemindMe className={'icon_size_reminder'} /> : ''
            }
            buttonText={
              !mobileDeskSize ? (
                <SVGRemindMe className={'icon_size_reminder'} />
              ) : (
                getText('WORD_REMINDER')
              )
            }
            className='icon-size'
            style={{ backgroundColor: 'transparent', padding: 0 }}
            disabled={conversation.isNew || conversation.kind === 'gbm'}
          />
        </Tooltip>
      }
      visible={visible}
      onTrrigerClick={() => {
        setVisible(true)
      }}
      onCancel={() => {
        setVisible(false)
      }}
      hideFooter
    >
      <DateTimePickerModal
        conversation={conversation}
        remindedDay={remindedDay}
        closePopover={() => {
          setVisible(false)
        }}
        title={title}
        addToList={addToList}
        unarchiveConversation={unarchiveConversation}
        unAssignConversation={unAssignConversation}
        onCloseMobileDesk={onCloseMobileDesk}
        trigger={
          <>
            {remindMeOptions.map((item) => (
              <MatButton
                key={item.value}
                typeButton={'radius'}
                onClick={() => setRemindedDay(item.value)}
                buttonText={item.label}
              />
            ))}
          </>
        }
      />
    </MatModal>
  )
}

export default memo(RemindMePicker)
