import React, { memo, useEffect, useRef, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useSelector } from 'react-redux'
import { useMixpanel } from 'react-mixpanel-browser'
import { enUS, fr, es } from 'date-fns/locale'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import { getText, getTextServerError } from '../../../../../lang'
import customerActions from '../../../../../store/modules/customerActions'
import {
  formatDateAppoinment,
  formatDateTimeAppoinment,
  formatTimeAppoinment,
  setMixpanel,
} from '../../../../../utils'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import SVGArrowDown from '../../../../../icons/SVG/SVGArrowDown'
import utilDate from '../../../../../utils/utilsDate'
import UtcTimezoneMessage from '../../../../../components/api/UtcTimezoneMessage'
import MatButton from '../../../../../components/MatButton'
import MatSelect from '../../../../../components/MatSelect'
import MessageSelectLocation from '../MessageSelectLocation'
import authActions from '../../../../../store/modules/authActions'
import { EMAIL, SMS } from '../../../../../devOptions'
import './MessageTabBooking.scss'

registerLocale('en', enUS)
registerLocale('sp', es)
registerLocale('fr', fr)

const RenderBookingEdit = ({
  item,
  onCancel,
  onRemove,
  onConfirm,
  placement,
  conversation,
  handleOnDelete,
  fromAppointment,
  overlayClassName,
  bookinEditClassName,
  onCloseMobileDesk,
  msgChangeEmailSMSType,
}) => {
  const mixpanel = useMixpanel()

  const currentTimeSplit = utilDate
    .getDateDaysJSUTC()
    .add(5, 'minutes')
    .format('hh:mm:a')
    .split(':')

  const userLang = authActions.getLanguage()

  const [dateTime, setdateTime] = useState('')
  const [saving, setsaving] = useState(false)
  const [savingDelete, setsavingDelete] = useState(false)
  const [minuteValue, setMinuteValue] = useState(currentTimeSplit[1])
  const [hourValue, setHourValue] = useState(currentTimeSplit[0])
  const [systemHour, setSystemHour] = useState(currentTimeSplit[2])
  const [calendarDate, setCalendarDate] = useState(utilDate.getDateByNewDateUTC())

  const currentHours = currentTimeSplit[0]
  const currentMinute = currentTimeSplit[1]

  const addAppointmentEndRef = useRef(null)

  const [selectedLocation, setselectedLocation] = useState('')

  let user = useSelector((state) => {
    return state.authReducer.user
  })

  let location = useSelector((state) => {
    return state.authReducer.user.location
  })

  useEffect(() => {
    let obj = {}
    if (item) {
      obj = {
        _id: item._location_id ? item._location_id : location._id,
        name: item.eventName ? item.eventName.substring(15) : location.name,
      }
    } else {
      obj = {
        _id: conversation._location_id ? conversation._location_id : location._id,
        name: conversation._location_id ? ' ' : location.name,
      }
    }
    setselectedLocation(obj)
  }, [conversation])

  const scrollToBottom = () => {
    addAppointmentEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
    if (item) {
      let date = new Date(item.eventStart)
      const splitedDate = utilDate
        .getDayJSDate(item.eventStart)
        .format('hh:mm:a')
        .split(':')
      setSystemHour(splitedDate[2])
      setHourValue(splitedDate[0])
      setMinuteValue(splitedDate[1])
      setCalendarDate(date)
    } else {
      var currentDate = new Date()
      currentDate.setMinutes(currentDate.getMinutes() + 5)
      let newDateObj = utilDate.getDateByNewDateUTC(currentDate)
      setSystemHour(currentTimeSplit[2])
      setHourValue(currentHours)
      setMinuteValue(currentMinute)
      setCalendarDate(newDateObj)
    }
  }, [item])

  useEffect(() => {
    let realHourValue
    if (systemHour === 'pm') {
      realHourValue = hourValue === '12' ? Number(hourValue) : Number(hourValue) + 12
    } else {
      realHourValue = hourValue === '12' ? 0 : Number(hourValue)
    }
    const fullDateSelected = new Date(calendarDate).setHours(
      realHourValue,
      minuteValue
    )
    const implementedData = new Date(fullDateSelected)
    if (implementedData instanceof Date && !isNaN(implementedData)) {
      setdateTime(implementedData)
    }
  }, [systemHour, hourValue, minuteValue, calendarDate])

  const handleSave = async () => {
    setsaving(true)
    let obj = item
    if (!obj) {
      obj = {}
    }
    obj.date = dateTime
    if (selectedLocation._id) {
      let result
      if (conversation.isNew) {
        obj.conversationId = conversation._id
      }
      if (!item || !item._id) {
        if (conversation.kind === SMS || conversation.isNew) {
          if (msgChangeEmailSMSType === EMAIL) {
            obj.customerId = conversation._receiver_id
          } else {
            let phone = conversation.receiver.phone
            phone = phone.replace('+', '')
            obj.phone = phone
          }
        } else {
          obj.messengerId = conversation.receiver.messengerId
        }
        setTimeout(() => {
          onRemove && onRemove()
          onCloseMobileDesk && onCloseMobileDesk()
        }, 1500)
        result = await customerActions.sendCalendarInvitation(
          obj,
          selectedLocation._id,
          conversation
        )
      } else {
        setTimeout(() => {
          onRemove && onRemove()
          onCloseMobileDesk && onCloseMobileDesk()
        }, 1500)
        result = await customerActions.updateCalendarInvitation(
          obj,
          fromAppointment ? obj._customer_id : conversation.receiver._id,
          fromAppointment ? obj._location_id : selectedLocation._id
        )
      }
      if (result.success) {
        let mmsg = item
          ? getText('NTF_SUCCESS_SEND_BOOKING_INVITATION')
          : getText('NTF_SUCCESS_SEND_BOOKING_INVITATION_NEW')

        notifySuccess(
          mmsg
            .replace('[Customer]', conversation?.receiver?.fullName || 'customer')
            .replace('[date]', formatDateAppoinment(dateTime))
            .replace('[time]', formatTimeAppoinment(dateTime))
        )

        if (!fromAppointment) {
          setsaving(false)
        } else {
          setsaving(false)
          onConfirm && onConfirm(result.data)
        }
        const { createdAt, fullName, organization, location, _id: userId } = user
        setMixpanel(
          mixpanel,
          'Calendar invite sent',
          createdAt,
          fullName,
          organization.name,
          location.name,
          null,
          organization._id,
          location._id,
          userId
        )
      } else {
        notifyError(getTextServerError(result.errMsg))
        setsaving(false)
      }
    }
  }

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target
    const [, fieldIndex] = name.split('-')
    let fieldIntIndex = parseInt(fieldIndex, 10)

    if (value.length >= maxLength) {
      if (fieldIntIndex < 2) {
        const nextfield = document.querySelector(
          `input[name=field-${fieldIntIndex + 1}]`
        )

        if (nextfield !== null) {
          nextfield.focus()
        }
      }
    }
  }

  return (
    <div className='booking-edit' ref={addAppointmentEndRef}>
      <MessageSelectLocation
        label={getText('WORD_LOCATION')}
        idLocation={selectedLocation._id}
        onGetItem={async (item) => {
          setselectedLocation(item)
        }}
        onSelectItem={async (item) => {
          setselectedLocation(item)
        }}
        disabled={item}
      />
      <div className={`booking-edit-wrapper ${bookinEditClassName || ''}`}>
        <div
          className='picker-with-label'
          style={{
            borderStyle: 'none',
            borderBottom: '1px solid var(--borderLineColor)',
            paddingTop: '0px',
          }}
        >
          <label className='picker-label'>{getText('WORD_DATE')}</label>
          <DatePicker
            className='row-actions'
            todayButton={getText('WORD_TODAY')}
            minDate={new Date()}
            selected={calendarDate}
            onChange={(date) => {
              setCalendarDate(date)
            }}
            customInput={
              <div className='row-actions'>
                <span className='date-text'>
                  {formatDateAppoinment(calendarDate)}
                </span>
                <SVGArrowDown style={{ marginLeft: 4 }} />
              </div>
            }
            locale={userLang === 'fr' ? fr : userLang === 'sp' ? es : enUS}
          />
        </div>
        <div className='picker-with-label'>
          <label className='picker-label'>{getText('WORD_TIME')}</label>
          <div className='time-picker-block-wrapper'>
            <div className='with-select'>
              <div className='time-picker-block'>
                <input
                  name='field-1'
                  value={hourValue}
                  type='number'
                  inputMode='numeric'
                  onChange={(e) => {
                    if (e.target.value.length === 3) {
                      return false
                    }
                    if (e.target.value > 12 || e.target.value < 0) {
                      return false
                    }
                    setHourValue(e.target.value)
                    handleChange(e)
                  }}
                  onBlur={(e) => {
                    if (e.target.value.length === 1) {
                      setHourValue(e.target.value.toString().padStart(2, '0'))
                      return
                    }
                  }}
                  maxLength={2}
                />
                <div className='point'>:</div>
                <input
                  name='field-2'
                  value={minuteValue}
                  type='number'
                  inputMode='numeric'
                  onChange={(e) => {
                    if (e.target.value.length === 3) {
                      return false
                    }
                    if (e.target.value > 59 || e.target.value < 0) {
                      return false
                    }
                    setMinuteValue(e.target.value)
                    handleChange(e)
                  }}
                  onBlur={(e) => {
                    if (e.target.value.length === 1) {
                      setMinuteValue('0' + e.target.value)
                    }
                  }}
                  maxLength={2}
                />
              </div>
              <MatSelect
                className={'mat-select-booking'}
                value={systemHour}
                options={[
                  {
                    label: <div style={{ paddingLeft: '13px' }}>AM</div>,
                    value: 'am',
                  },
                  {
                    label: <div style={{ paddingLeft: '13px' }}>PM</div>,
                    value: 'pm',
                  },
                ]}
                onSelectItem={(item) => {
                  setSystemHour(item.value)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <UtcTimezoneMessage
        style={{ marginTop: '10px' }}
        title={getText('TEXT_APPOINTMENT_TIME_ZONE')}
      />
      <div className='btns-wrapper'>
        {fromAppointment ? (
          <ConfirmPopup
            className={overlayClassName}
            title={getText('TEXT_ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_APPOINTMENT')}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            onConfirm={async () => {
              setsavingDelete(true)
              if (item) {
                let result = await customerActions.deleteCalendarInvitation(
                  item._id,
                  item._customer_id,
                  item._location_id
                )
                if (result.success) {
                  handleOnDelete && handleOnDelete(item)
                  notifySuccess(
                    getText('NTF_SUCCESS_DELETED_BOOKING_INVITATION')
                      .replace(
                        '[Customer]',
                        item.customer && item.customer.fullName
                          ? item.customer.fullName
                          : getText('WORD_CUSTOMER').toLowerCase()
                      )
                      .replace('[date]', formatDateTimeAppoinment(item.eventStart))
                  )
                } else {
                  notifyError(result.errMsg)
                }
              }
              setsavingDelete(false)
            }}
            placement={placement || 'leftTop'}
            trigger={
              <MatButton
                loading={savingDelete}
                buttonText={getText('ACTION_DELETE')}
                typeButton={'cancel'}
                size={'small'}
              />
            }
          />
        ) : (
          <MatButton
            onClick={() => {
              onCancel && onCancel()
            }}
            disabled={saving}
            buttonText={getText('ACTION_CANCEL')}
            typeButton={'cancel'}
            size={'small'}
          />
        )}
        {item ? (
          <ConfirmPopup
            className={overlayClassName}
            title={getText('MSG_UPDATE_BOOKING_INVITATION')}
            onConfirm={handleSave}
            onCancel={() => {
              onCancel && onCancel()
            }}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            placement={placement || 'leftTop'}
            trigger={
              <MatButton
                loading={saving}
                buttonText={getText('ACTION_UPDATE')}
                typeButton={'white'}
                size={'small'}
              />
            }
          />
        ) : (
          <ConfirmPopup
            title={`${getText('TEXT_ARE_YOU_SURE_THIS_APPOINTMENT_IS_FOR')} ${
              selectedLocation.name || ' '
            }?`}
            onConfirm={handleSave}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            trigger={
              <MatButton
                loading={saving}
                buttonText={getText('ACTION_SEND')}
                typeButton={'white'}
                size={'small'}
              />
            }
          />
        )}
      </div>
    </div>
  )
}

export default memo(RenderBookingEdit)
