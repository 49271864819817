import React, { memo } from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import SVGPhoneCall from '../../../../../icons/SVG/SVGPhoneCall'
import { getText } from '../../../../../lang'
import MatButton from '../../../../../components/MatButton'
import {
  msgTooltipText,
  sendCustomPhone,
  sendPhoneCall,
} from '../../../utils/messageUtils'
import { MESSENGER } from '../../../utils/constants'

const MessageCallPhoneButton = (props) => {
  const { conversation, disabled, mobileDeskSize, onCloseMobileDesk } = props

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  if (conversation.is_archived) {
    return null
  }

  return props.conversation.kind !== MESSENGER && conversation.receiver_phone ? (
    <Tooltip
      overlayStyle={{ zIndex: mobileDeskSize ? '-1000' : '10001' }}
      destroyTooltipOnHide={true}
      trigger={['hover', 'click']}
      placement='top'
      title={getText('WORD_PHONE_CALL')}
    >
      <MatButton
        dataIndex={'phone_call'}
        className={'icon-size only-stroke footerAction'}
        disabled={disabled || conversation.isActiveVoiceCall}
        onClick={() => {
          onCloseMobileDesk && onCloseMobileDesk()
          if (
            organization.restrictions
              ?.restrictUserVoiceCallsFromUsingOrganizationNumbers
          ) {
            sendCustomPhone(conversation, isMobileApp)
          } else {
            sendPhoneCall(conversation.receiver_phone, conversation)
          }
        }}
        icon={<SVGPhoneCall />}
        buttonText={mobileDeskSize ? getText('WORD_PHONE_CALL') : ''}
      />
    </Tooltip>
  ) : (
    <Tooltip title={msgTooltipText(conversation) || getText('WORD_PHONE_CALL')}>
      <div
        dataIndex={'phone_call'}
        className={'mat-btn icon-size only-stroke footerAction'}
        disabled={true}
      >
        <span className='ant-btn-icon'>
          <SVGPhoneCall color={'var(--thridyTextColor)'} />
        </span>
        {mobileDeskSize ? (
          <span>{msgTooltipText(conversation) || getText('WORD_PHONE_CALL')}</span>
        ) : (
          ''
        )}
      </div>
    </Tooltip>
  )
}

export default memo(MessageCallPhoneButton)
