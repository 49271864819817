import React from 'react'
import { Tooltip } from 'antd'
import { getText, getTextServerError } from '../../../../lang'
import Button from '../../../../components/Button'
import messageActions from '../../../../store/modules/messageActions'
import { notifyError } from '../../../../utils/Notify'
import MatSwitchSecondary from '../../../../components/MatSwitch/MatSwitchSecondary'
import { formatDateTime } from '../../../../utils'
import userActions from '../../../../store/modules/userActions'

const SuperhumanSequence = ({
  value,
  organization,
  saving,
  icon,
  title,
  color,
  className,
  handleOnChange,
  disabled,
  hidden,
  conversation,
  user,
  isFollowUp,
}) => {
  const superhumanSnoozedEnabled =
    organization?.restrictions?.allowSuperhumanActionInConversation?.enabled

  return hidden ? null : (
    <div className={`block_right_and_left_sides ${className || ''}`}>
      <div className='block_right_and_left_sides--left-side'>
        <div
          className={
            `block_right_and_left_sides--left-side-icon ${color} ` +
            (value ? ' active_bot' : ' ')
          }
        >
          {icon}
        </div>
        <div className='block_right_and_left_sides--left-side-label'>
          <span className={value ? 'active_bot' : 'not_active'}>{title}</span>
        </div>
        {Boolean(
          conversation?.receiver?.superhumanConfig?.snoozedUntil &&
            new Date(conversation?.receiver?.superhumanConfig?.snoozedUntil) >
              new Date()
        ) &&
          superhumanSnoozedEnabled &&
          !isFollowUp && (
            <Tooltip
              title={
                <div className='snoozed_item_tip'>
                  {`${getText('TEXT_SUPERHUMAN_WILL_BE_REENGAGED_CONVERSATION_AT')} ${formatDateTime(conversation?.receiver?.superhumanConfig?.snoozedUntil)}`}
                  {Boolean(
                    userActions.getUserHasPermission(
                      user,
                      organization.restrictions?.allowSuperhumanActionInConversation
                    )
                  ) && (
                    <>
                      <br />
                      <Button
                        text={getText('WORD_REVERT_DECISION')}
                        handleClick={async () => {
                          const result = await messageActions.snoozeSuperhumanAction(
                            conversation._id,
                            false
                          )
                          if (!result) {
                            notifyError(getTextServerError(result.errMsg))
                          }
                        }}
                      />
                    </>
                  )}
                </div>
              }
            >
              <div className='snoozed_item'>{getText('WORD_SNOOZED')}</div>
            </Tooltip>
          )}
      </div>
      <MatSwitchSecondary
        disabled={
          isFollowUp
            ? disabled
            : disabled || !organization?.superhumanConfig?.enabled
        }
        value={value}
        onChange={(value) => handleOnChange(value)}
        loading={saving}
      />
    </div>
  )
}

export default SuperhumanSequence
