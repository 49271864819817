import React, { useEffect, useRef, useState } from 'react'
import { Button, Tooltip } from 'antd'
import { getText } from '../../lang'
import './MatButton.scss'

const MatButton = (props) => {
  const buttonRef = useRef(null)
  const [isEllipsed, setIsEllipsed] = useState(false)

  useEffect(() => {
    const parent = buttonRef?.current
    let span
    if (parent) {
      span = parent.querySelector('.ant-btn-icon')
      if (span) {
        span = parent.querySelector('span + span')
      } else {
        span = parent.querySelector('span')
      }
      const isTextEllipsed = span?.scrollWidth > span?.clientWidth
      setIsEllipsed(isTextEllipsed)
    }
  }, [])

  return props.tooltip || isEllipsed ? (
    <Tooltip title={props.tooltipTitle || props.buttonText}>
      <Button
        ref={buttonRef}
        id={props.id}
        dataIndex={props.dataIndex}
        type={props.typeButton}
        htmlType={props.htmlType}
        form={props.formName}
        onClick={props.onClick}
        loading={props.loading}
        icon={props.icon}
        style={{ borderRadius: props.radius, ...props.style }}
        className={`mat-btn ${props.typeButton ? `mat-btn-${props.typeButton}` : ''} ${
          props.size ? `mat-btn-${props.size}` : ''
        } ${props.className || ''}`}
        disabled={props.disabled}
        size={props.size}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onMouseDown={props.onMouseDown}
        onMouseUp={props.onMouseUp}
        onTouchStart={props.onTouchStart}
        onTouchEnd={props.onTouchEnd}
      >
        {props.buttonText || ''}
      </Button>
    </Tooltip>
  ) : (
    <Button
      ref={buttonRef}
      id={props.id}
      dataIndex={props.dataIndex}
      type={props.typeButton}
      htmlType={props.htmlType}
      form={props.formName}
      onClick={props.onClick}
      loading={props.loading}
      icon={props.icon}
      style={{ borderRadius: props.radius, ...props.style }}
      className={`mat-btn ${props.typeButton ? `mat-btn-${props.typeButton}` : ''} ${
        props.size ? `mat-btn-${props.size}` : ''
      } ${props.className || ''}`}
      disabled={props.disabled}
      size={props.size}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      onTouchStart={props.onTouchStart}
      onTouchEnd={props.onTouchEnd}
    >
      {props.buttonText || ''}
    </Button>
  )
}

MatButton.defaultProps = {
  text: getText('ACTION_SAVE'),
  typeButton: 'primary',
  formName: 'formSubmit',
  htmlType: 'submit',
}

export default MatButton
