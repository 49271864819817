import { getMobileOperatingSystem } from '../../utils'
import {
  fetchFromUrlDELETEAsync,
  fetchFromUrlGETAsync,
  fetchFromUrlPATCHAsync,
  fetchFromUrlPOSTAsync,
  fetchFromUrlPUTAsync,
} from '../../utils/UrlHelper'
import authActions from './authActions'

const urlUsers = '/users'
const urlUsersAssignUnassign = '/users/assign-list'
const urlUserProfile = '/users/profile'
const urlSendFCMToken = '/users/connect-device'

const fs = {
  getSelfProfile: async () => {
    const result = await fetchFromUrlGETAsync(urlUserProfile, [])
    return result
  },

  refreshUserFS: async () => {
    const result = await fetchFromUrlGETAsync(urlUserProfile, [])
    if (result.success) {
      await authActions.setUserData(result.data)
    }
    return result
  },
  // For getting organization users please pass query param organization_id in /v1/users endpoint

  getPageList: async (start, range, searchKeyword = {}, paginationOptions = {}) => {
    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
    ]
    for (let filter in searchKeyword) {
      searchKeyword[filter] &&
        urlParams.push({ key: filter, value: searchKeyword[filter] })
    }

    if (paginationOptions.isManager) {
      urlParams.push({ key: 'isManager', value: true })
    }

    const result = await fetchFromUrlGETAsync(urlUsers, urlParams)
    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.users
    }
    return result
  },

  getUsersForAssignUnassign: async (
    start,
    range,
    orgId,
    searchKeyword = '',
    listParameter = {}
  ) => {
    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
      { key: 'enabled', value: true },
    ]

    if (searchKeyword) {
      urlParams.push({ key: 'search', value: searchKeyword })
    }

    if (listParameter.fullList) {
      urlParams.push({ key: 'fullList', value: listParameter.fullList })
    }

    if (listParameter.withoutSAdmins) {
      urlParams.push({
        key: 'withoutSAdmins',
        value: listParameter.withoutSAdmins,
      })
    }

    const result = await fetchFromUrlGETAsync(
      urlUsersAssignUnassign + '/' + orgId,
      urlParams
    )

    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.users
    }
    return result
  },

  getSingle: async (itemId) => {
    const result = await fetchFromUrlGETAsync(urlUsers + '/' + itemId, [])
    return result
  },

  changeOrgByAdmin: async (itemId, _organization_id) => {
    const urlParams = [{ key: '_organization_id', value: _organization_id }]
    const result = await fetchFromUrlPATCHAsync(urlUsers + '/' + itemId, urlParams)
    return result
  },

  saveNew: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(urlUsers, urlParams)
    return result
  },

  saveUpdate: async (obj, userId) => {
    if (obj.phone) {
      obj.phone = obj.phone.replace('+', '')
    } else {
      obj.phone = ''
    }
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPUTAsync(urlUsers + '/' + userId, urlParams)
    return result
  },

  setAdditionalConfigs: async (obj, userId) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    return fetchFromUrlPATCHAsync(
      urlUsers + '/' + userId + '/additional-configs',
      urlParams
    )
  },

  changeLocation: async (location_id, userId) => {
    const urlParams = [{ key: '_location_id', value: location_id }]
    const result = await fetchFromUrlPATCHAsync(
      `/users/${userId}/location`,
      urlParams
    )
    return result
  },

  changeSuperAdminOrganization: async (organization_id, userId) => {
    const urlParams = [{ key: '_organization_id', value: organization_id }]
    const result = await fetchFromUrlPATCHAsync(urlUsers + '/' + userId, urlParams)
    return result
  },

  downloadUsersXLS: async (userStatus = 'active') => {
    // TODO. FE team can you please check?
    // We have "SyntheticBaseEvent" object in "userStatus"
    const fileContent = await fetchFromUrlGETAsync(
      // `/users/active-users/download?status=${userStatus'}`
      `/users/active-users/download?status=active`
    )
    const { data: url = '' } = fileContent || {}

    if (url) {
      if (getMobileOperatingSystem() === 'iOS') {
        setTimeout(() => {
          window.open(url)
        })
      } else {
        window.open(url, '_blank')
      }
    }
  },

  sendFCMToken: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(urlSendFCMToken, urlParams)
    authActions.setFCMToken(obj.fcm_token)
    return result
  },

  deleteFCMToken: async () => {
    const token = authActions.getFCMToken()
    if (token) {
      const urlParams = [{ key: 'fcm_token', value: token }]
      const url = urlUsers + '/disconnect-device'
      const result = await fetchFromUrlDELETEAsync(url, urlParams)
      return result
    }
  },
}

const loc = {
  getUserHasPermission: (user, restriction, isManagerAllowed) => {
    if (!user) return false

    if (user.isAdmin || user.isSuperAdmin || (isManagerAllowed && user.isManager))
      return true

    if (!restriction) return false

    const userRole = user.isManager
      ? 'Manager'
      : user.isSupportAgent
        ? 'Support agent'
        : null
    if (!userRole) return false

    if (typeof restriction === 'object')
      return restriction.enabled && restriction.roles.includes(userRole)

    return restriction
  },
}

const userActions = Object.assign(fs, loc)

export default userActions
