import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Flex, Tooltip } from 'antd'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import SwitchWithText from '../../components/SwitchWithText'
import { getText } from '../../../../lang'
import RestrictionsCheckboxGroup from './RestrictionsCheckboxGroup'

const getToolTipText = (key) => {
  let text = ''
  switch (key) {
    case 'allowFollowUp':
      text = getText('TEXT_FOLLOW_UP_TOOLTIP')
      break
    case 'restrictSendingBroadcastsOverTheWeekend':
      text = getText('restrictSendingBroadcastsOverTheWeekend_TOOLTIP')
      break

    default:
      break
  }

  return text
}

const TabItems = ({ items, restrictions, onSwitchChange, onCheckBoxPress }) => {
  return (
    <MyDefaultBlock>
      {items.map((key) => {
        const isWithRoles = typeof restrictions[key] === 'object'
        const value = restrictions[key]
        return (
          <MatBlockWithLabel className='restriction-item' key={key}>
            <SwitchWithText
              title={
                key === 'allowFollowUp' ||
                key === 'restrictSendingBroadcastsOverTheWeekend' ? (
                  <Flex align='center' gap={4}>
                    {getText(key)}{' '}
                    <Tooltip title={getToolTipText(key)}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Flex>
                ) : (
                  getText(key)
                )
              }
              value={isWithRoles ? value.enabled : value}
              noText
              onChangeSwitch={(newValue) => {
                onSwitchChange && onSwitchChange(key, newValue)
              }}
            />
            {isWithRoles && (
              <RestrictionsCheckboxGroup
                key={key + value}
                currentRestrictionName={key}
                currentRestriction={value}
                onCheckBoxPress={onCheckBoxPress}
              />
            )}
          </MatBlockWithLabel>
        )
      })}
    </MyDefaultBlock>
  )
}

export default TabItems
