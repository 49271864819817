import React, { memo, useMemo } from 'react'
import { Layout, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import authActions from '../../store/modules/authActions'
import userActions from '../../store/modules/userActions'
import { getText } from '../../lang'
import { moveToLoginPage } from '../../utils'
import SVGAntdArrowDown from '../../icons/SVG/SVGAntArrowDown'
import SVGDrawerOpenIcon from '../../icons/SVG/SVGDrawerOpenIcon'
import SVGDrawerManageLocationsPurple from '../../icons/SVG/SVGDrawerManageLocationsPurple'
import SVGOrganization from '../../icons/SVG/SVGOrganization'
import MatDropMenu from '../../components/MatDropMenu'
import MatSelect from '../../components/MatSelect'
import SelectLocations from '../../components/api/SelectLocations'
import SelectOrganization from '../../components/api/SelectOrganization'
import SiderToggle from './SiderToggle'
import './MainHeader.scss'
import { organizationChangingNavigate } from './headerUtils'

const deviceSize = ['tabletPortrait', 'mobile']

const MainHeader = (props) => {
  const { pageTitle, screenSize, isExtension } = props

  const navigate = useNavigate()
  const path = useLocation()

  const isSuperAdmin = useSelector((state) => {
    return state.authReducer.user.isSuperAdmin || false
  })
  const user = useSelector((state) => state.authReducer.user)

  const isAdmin = useSelector((state) => {
    return state.authReducer.user.isAdmin || false
  })

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const location = useSelector((state) => {
    return state.authReducer.user.location
  })

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  const hasManyOrganizations = useSelector((state) => {
    return (
      state.authReducer.user.accessibleOrganizations &&
      state.authReducer.user.accessibleOrganizations.length >= 2
    )
  })

  const ProfileEdit = useMemo(() => {
    return (
      isMobileApp && (
        <MatDropMenu
          bigText
          options={[
            {
              name: getText('TITLE_MESSAGES'),
              value: 'messages',
            },
            {
              name: getText('ACTION_PROFILE'),
              value: 'profile',
            },
            {
              name: getText('ACTION_LOGOUT'),
              value: 'logout',
            },
          ]}
          overlayStyle={{ width: '100px' }}
          showArrow={false}
          className={'header-mobile-account'}
          trigger={<SVGDrawerOpenIcon />}
          onChange={async (val) => {
            if (val === 'profile') {
              navigate('/profile/edit')
            } else if (val === 'logout') {
              moveToLoginPage(navigate)
            } else if (val === 'messages') {
              navigate('/messages' + path.search, {
                state: { centerDrawerFalse: false },
              })
            }
          }}
        />
      )
    )
  }, [isMobileApp])

  const Title = useMemo(() => {
    return <div className='mat-header-page-title'>{pageTitle}</div>
  }, [pageTitle])

  const OrganizationSelectContent = useMemo(() => {
    return (
      <>
        {(isSuperAdmin || authActions.hasAccessToMultipleOrganizations()) &&
        !window.location.pathname.includes('/dashboard') &&
        !isSuperAdmin &&
        !isMobileApp &&
        !isExtension ? (
          <>
            <Tooltip
              title={getText(
                'TOOLTIP_FOR_CHANGE_THE_ORGANIZATION_PLEASE_GO_TO_DASHBOARD_PAGE'
              )}
              placement={deviceSize.includes(screenSize) ? 'bottomRight' : 'bottom'}
              trigger={['click']}
            >
              <div className='mat-header-select-tooltip'>
                <div className='mat-header-select-tooltip-icon-with-text'>
                  <div className='select-left-icon'>
                    <SVGOrganization />
                  </div>
                  <div className='select-name'>
                    {organization && organization.name}
                  </div>
                </div>
                <SVGAntdArrowDown />
              </div>
            </Tooltip>
          </>
        ) : (
          <SelectOrganization
            showSearch={true}
            primary
            dropdownClassName={`mat-header-dropdown-responsive-first`}
            dropdownStyle={
              deviceSize.includes(screenSize) && !isExtension
                ? { minWidth: 240, maxWidth: 240 }
                : {}
            }
            onChangeOrganization={(organization) => {
              organizationChangingNavigate(organization, navigate, isMobileApp)
            }}
          />
        )}
      </>
    )
  }, [isSuperAdmin, screenSize, organization, isMobileApp, pageTitle, isExtension])

  const LocationSelectContent = useMemo(() => {
    return (
      <>
        {hasManyOrganizations ||
        userActions.getUserHasPermission(
          user,
          organization.restrictions?.allowAccessToAllLocations
        ) ? (
          <SelectLocations
            primary
            dropdownClassName={`mat-header-dropdown-responsive-second 
              }`}
            dropdownStyle={
              deviceSize.includes(screenSize) && !isExtension
                ? { minWidth: 240, maxWidth: 240, right: 30 }
                : {}
            }
          />
        ) : location && (isMobileApp || screenSize === 'mobile') ? (
          <MatSelect
            icon={<SVGDrawerManageLocationsPurple />}
            options={[
              {
                label: location.name,
                value: location._id,
              },
            ]}
            primary
            dropdownClassName={`mat-header-dropdown-responsive-second 
              }`}
            dropdownStyle={
              deviceSize.includes(screenSize) && !isExtension
                ? { minWidth: 240, maxWidth: 240, right: 30 }
                : {}
            }
            value={location._id}
          />
        ) : (
          <Tooltip placement={'bottomRight'} title={location ? location.name : ''}>
            <div className='location-without-select'>
              {location ? location.name : ''}
            </div>
          </Tooltip>
        )}
      </>
    )
  }, [isMobileApp, screenSize, isAdmin, organization, location])

  const SideBarContent = useMemo(() => {
    return !isExtension && <>{!isMobileApp ? <SiderToggle /> : undefined}</>
  }, [isMobileApp, isExtension])

  return (
    <Layout.Header className='mat-header'>
      {SideBarContent}
      {ProfileEdit}
      {Title}
      <div className={'mat-header-selects-wrapper'}>
        {OrganizationSelectContent}
        {LocationSelectContent}
      </div>
    </Layout.Header>
  )
}

export default memo(MainHeader)
