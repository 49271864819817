import React, { memo, useEffect, useState } from 'react'
import { Empty, List } from 'antd'
import { useSelector } from 'react-redux'
import SVGCloseRound from '../../../../../icons/SVG/SVGCloseRound'
import SVGEdit from '../../../../../icons/SVG/SVGEdit'
import { getText } from '../../../../../lang'
import customerActions from '../../../../../store/modules/customerActions'
import {
  formatDateTimeAppoinment,
  getCustomerPhoneInvlid,
} from '../../../../../utils'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MatButton from '../../../../../components/MatButton'
import { MOBILE, RED, YELLOW } from '../../../../../devOptions'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import RenderBookingEdit from './RenderBookingEdit'

const MessageTabBooking = (props) => {
  const { conversation, screenSize, isAwaitingConsent, msgChangeEmailSMSType } =
    props
  const [listBooking, setlistBooking] = useState([])
  const [loading, setloading] = useState(false)
  const [editingIndex, setEditingIndex] = useState(false)

  let user = useSelector((state) => {
    return state.authReducer.user
  })

  let receiver = conversation.receiver
  useEffect(() => {
    setloading(true)
    if (conversation && receiver) {
      setlistBooking(receiver.appointments)
    }
    setEditingIndex(false)
    setloading(false)
  }, [conversation, receiver])

  const handleOnDelete = async (item) => {
    if (item) {
      let result = await customerActions.deleteCalendarInvitation(
        item._id,
        receiver._id,
        item._location_id
      )
      if (result.success) {
        notifySuccess(
          getText('NTF_SUCCESS_DELETED_BOOKING_INVITATION')
            .replace(
              '[Customer]',
              receiver.fullName || getText('WORD_CUSTOMER').toLowerCase()
            )
            .replace('[date]', formatDateTimeAppoinment(item.eventStart))
        )
        setlistBooking(result.data.appointments)
      } else {
        notifyError(result.errMsg)
      }
    }
  }

  const isCustomerRedStatus = conversation?.receiver?.status?.state === RED
  const isCustomerYellowStatusAndRestricted =
    conversation?.receiver?.status?.state === YELLOW &&
    !user.organization.restrictions?.allowTextingCustomersInPendingStatus
  const isCustomerPhoneNumberValid = !conversation?.receiver?.phoneStatus?.valid
  const isCustomerPhoneTemporaryBlocked = Boolean(
    getCustomerPhoneInvlid(conversation)
  )

  return (
    <div className='message-tab-pane-wrapper'>
      <List
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={getText('WORD_NO_DATA')}
            />
          ),
        }}
        loading={loading}
        dataSource={listBooking}
        header={
          <div className='tab-note-header'>
            {getText('WORD_APPOINTMENTS_DATE')}
            {
              <MatButton
                disabled={
                  isCustomerPhoneNumberValid ||
                  isCustomerRedStatus ||
                  isCustomerYellowStatusAndRestricted ||
                  conversation.is_archived ||
                  isCustomerPhoneTemporaryBlocked ||
                  isAwaitingConsent
                }
                onClick={() => {
                  setEditingIndex('new')
                }}
                tooltip={isAwaitingConsent}
                tooltipTitle={getText(
                  'TEXT_YOU_CANT_TEXT_CUSTOMERS_WHILE_AWAITING_CONSENT'
                )}
                buttonText={`+ ${getText('WORD_ADD_NEW')}`}
              />
            }
          </div>
        }
        footer={
          editingIndex === 'new' ? (
            <RenderBookingEdit
              item={null}
              placement={screenSize === 'mobile' && 'topLeft'}
              conversation={conversation}
              msgChangeEmailSMSType={msgChangeEmailSMSType}
              onCancel={() => {
                setEditingIndex(false)
              }}
            />
          ) : null
        }
        renderItem={(item, index) => {
          let isDeleted = item.action === 'deleted'
          return (
            <List.Item
              key={item && item._id ? item._id : index}
              className='note-list-item'
            >
              {editingIndex === index ? (
                <RenderBookingEdit
                  placement={screenSize === MOBILE && 'topLeft'}
                  item={item}
                  conversation={conversation}
                  msgChangeEmailSMSType={msgChangeEmailSMSType}
                  onCancel={() => {
                    setEditingIndex(false)
                  }}
                />
              ) : (
                <>
                  <span> {formatDateTimeAppoinment(item.eventStart)}</span>
                  {isDeleted ? (
                    <div className='canceled-label'>{getText('WORD_CANCELED')}</div>
                  ) : (
                    Boolean(!conversation.is_archived && !isAwaitingConsent) && (
                      <>
                        <div className='edit-with-delete'>
                          <SVGEdit
                            onClick={() => {
                              setEditingIndex(index)
                            }}
                          />
                          <ConfirmPopup
                            title={getText(
                              'TEXT_ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_APPOINTMENT'
                            )}
                            onConfirm={() => {
                              handleOnDelete(item)
                            }}
                            okText={getText('WORD_YES')}
                            cancelText={getText('WORD_NO')}
                            placement='leftTop'
                            trigger={<SVGCloseRound />}
                          />
                        </div>
                      </>
                    )
                  )}
                </>
              )}
            </List.Item>
          )
        }}
      />
    </div>
  )
}
export default memo(MessageTabBooking)
