import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import messageActions from '../../../../../store/modules/messageActions'
import userActions from '../../../../../store/modules/userActions'
import { getText } from '../../../../../lang'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MatButton from '../../../../../components/MatButton'
import AssignManager from './AssignManager'
import './MessageAssignUsersSelector.scss'

const RenderAssignToggleForSupportAgent = (props) => {
  const { messageType, conversation, removeConversationFromList } = props

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  const userID = user._id

  const assigned = conversation.users.includes(userID)
  const handleUnAssignUser = async (userId) => {
    const result = await messageActions.unassignUser(conversation._id, userId)
    if (result.success) {
      if (
        !userActions.getUserHasPermission(
          user,
          user.organization.restrictions?.allowSupportAgentsAccessToMainInboxFolder,
          true
        )
      ) {
        removeConversationFromList(result.data.conversation)
      }
    } else {
      notifyError(result.errMsg)
    }
  }

  const handleAssignUser = async (userId) => {
    const result = await messageActions.assignUser(conversation._id, userId)
    if (result.success) {
      if (
        !userActions.getUserHasPermission(
          user,
          user.organization.restrictions?.allowSupportAgentsAccessToMainInboxFolder,
          true
        )
      ) {
        removeConversationFromList(result.data.conversation)
      }
      notifySuccess(getText('NTF_SUCCESS_MSG_ASSIGN'))
    } else {
      notifyError(result.errMsg)
    }
  }

  return (
    <MatButton
      typeButton={assigned ? 'white' : 'primary'}
      size={'small'}
      className={'self_assign_button'}
      onClick={(e) =>
        assigned ? handleUnAssignUser(userID) : handleAssignUser(userID)
      }
      buttonText={assigned ? getText('ACTION_UNASSIGN') : getText('ACTION_ASSIGN')}
      disabled={messageType === 'archived'}
    />
  )
}

const MessageAssignSelector = (props) => {
  const {
    conversation,
    messageType,
    refreshConversation,
    addToList,
    removeConversationFromList,
    screenSize,
  } = props
  const hidden = ['archived'].includes(messageType)

  let isSupportAgent = useSelector((state) => {
    return state.authReducer.user.isSupportAgent
  })

  return conversation &&
    conversation.isNew ? null : hidden ? null : isSupportAgent ? (
    <RenderAssignToggleForSupportAgent
      messageType={messageType}
      conversation={conversation}
      removeConversationFromList={removeConversationFromList}
    />
  ) : (
    <>
      {
        <AssignManager
          screenSize={screenSize}
          conversation={conversation}
          refreshConversation={refreshConversation}
          addToList={addToList}
        />
      }
    </>
  )
}

export default memo(MessageAssignSelector)
