import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Flex, Tooltip } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import MessageAssignSelector from '../MessageAssignUsersSelector/MessageAssignSelector'
import SVGArrowLeft from '../../../../../icons/SVG/SVGArrowLeft'
import ProfileLetters from '../../../../../components/ProfileLetters'
import { checkFormatPhoneNumber, getCustomerName } from '../../../../../utils'
import SVGMaximizeIcon from '../../../../../icons/SVG/SVGMaximizeIcon'
import SVGMaximizeMobileIcon from '../../../../../icons/SVG/SVGMaximizeMobileIcon'
import MessageCenterArchiveButton from '../MessageArchiveAction/MessageCenterArchiveButton'
import MessageCenterHeaderCRMs from '../MessageCRMAction/MessageCenterHeaderCRMs'
import MessageCenterInterest from '../MessageIntents/MessageCenterInterest'
import CenterAISummerize from '../../centerMessageBody/CenterMessageItems/CenterAISummerize'
import { BROADCAST } from '../../../utils/constants'
import { notifySuccess } from '../../../../../utils/Notify'
import { getText } from '../../../../../lang'
import SVGCopyIcon from '../../../../../icons/SVG/SVGCopyIcon'
import { GREEN, RED, YELLOW } from '../../../../../devOptions'
import SVGCustomerRefusedIcon from '../../../../../icons/SVG/SVGCustomerRefusedIcon'
import SVGCustomerPendingIcon from '../../../../../icons/SVG/SVGCustomerPendingIcon'
import SVGCustomerApprovedIcon from '../../../../../icons/SVG/SVGCustomerApprovedIcon'
import './MessageCenterMain.scss'

const deviceSizeMobile = ['tabletPortrait', 'mobile']
const deviceSizeTablet = ['tabletPortrait', 'mobile', 'tablet']

const MessageCenterHeader = (props) => {
  const {
    removeConversationFromList,
    closeCenterDrawer,
    messageType,
    screenSize,
    conversation,
    addToList,
    showRightBox,
    refreshConversation,
    isBlueExtension,
  } = props

  let aiSummary = useSelector((state) => {
    return state.authReducer.aiSummary
  })

  const receiver = conversation?.receiver
  const receiverName = getCustomerName(conversation)
  const receiverEmail = receiver.email
  const receiverPhone = checkFormatPhoneNumber(receiver.phone)
  const isSmallSize = deviceSizeMobile.includes(screenSize)

  const getCustomerStatus = (status) => {
    switch (status) {
      case RED:
        return {
          icon: <SVGCustomerRefusedIcon />,
          text: getText('WORD_OPTED_OUT'),
        }
      case YELLOW:
        return {
          icon: <SVGCustomerPendingIcon />,
          text: getText('WORD_PENDING'),
        }
      case GREEN:
        return {
          icon: <SVGCustomerApprovedIcon />,
          text: getText('WORD_OPTED_IN'),
        }
      default:
        break
    }
  }

  const customerStatus = getCustomerStatus(
    receiverName.includes('@')
      ? receiver?.status?.email?.state === YELLOW
        ? null
        : receiver?.status?.email?.state
      : receiver?.status?.state
  )

  return (
    <div className='message_page_center_panel_header'>
      <div className={'message_page_center_panel_header--header'}>
        {!deviceSizeTablet.includes(screenSize) ? (
          <React.Fragment>
            <div className='message_page_center_panel_header--header--left'>
              <div className='message_page_center_panel_header--header--left-name-block'>
                <ProfileLetters
                  className='contact-profile-letters medium'
                  name={receiverName}
                  withoutIcon
                />
                <span className='name'>{receiverName}</span>
                {screenSize !== 'desktop' ? (
                  <SVGMaximizeIcon
                    className={'show_right_panel'}
                    onClick={showRightBox}
                  />
                ) : null}
                {!conversation.isNew && (
                  <MessageCenterHeaderCRMs
                    conversation={conversation}
                    addToList={addToList}
                    refreshConversation={refreshConversation}
                  />
                )}
              </div>
            </div>
            <div className='message_page_center_panel_header--header--right'>
              <MessageAssignSelector
                screenSize={screenSize}
                messageType={messageType}
                conversation={conversation}
                removeConversationFromList={removeConversationFromList}
              />
              <MessageCenterArchiveButton
                conversation={conversation}
                messageType={messageType}
                removeConversationFromList={removeConversationFromList}
              />
              {messageType !== BROADCAST && !conversation.isNew && (
                <CenterAISummerize
                  conversation={conversation}
                  aiSummaryMessage={aiSummary}
                />
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className='message_page_center_panel_header--header--left'>
              {!isBlueExtension && isSmallSize ? (
                <SVGArrowLeft
                  className='drawer_close_icon'
                  color={'#474656'}
                  onClick={closeCenterDrawer}
                />
              ) : null}
              <div className='message_page_center_panel_header--header--left-name-block'>
                <Flex align={isSmallSize ? 'start' : 'center'}>
                  {!isSmallSize ||
                    (isBlueExtension && (
                      <ProfileLetters
                        className='contact-profile-letters medium'
                        name={receiverName}
                        withoutIcon
                      />
                    ))}
                  <div>
                    <span className='name'>
                      {!receiver.fullName ? (
                        <div className='customer_contact'>
                          <Tooltip
                            title={customerStatus?.text}
                            destroyTooltipOnHide={true}
                            trigger={['hover', 'click']}
                            placement='right'
                          >
                            <div className='customer_contact--icon'>
                              {customerStatus?.icon}
                            </div>
                          </Tooltip>
                          <span>{receiverName}</span>
                          <CopyToClipboard
                            text={receiverName}
                            onCopy={() => {
                              if (receiverName.includes('@')) {
                                notifySuccess(getText('WORD_EMAIL_COPIED'))
                              } else {
                                notifySuccess(getText('WORD_PHONE_COPIED'))
                              }
                            }}
                          >
                            <SVGCopyIcon />
                          </CopyToClipboard>
                        </div>
                      ) : (
                        receiverName
                      )}
                    </span>
                    {isSmallSize && (
                      <React.Fragment>
                        {receiver.fullName && receiverPhone && (
                          <div className='customer_contact'>
                            <Tooltip
                              title={customerStatus?.text}
                              destroyTooltipOnHide={true}
                              trigger={['hover', 'click']}
                              placement='right'
                            >
                              <div className='customer_contact--icon'>
                                {customerStatus?.icon}
                              </div>
                            </Tooltip>
                            <span>{receiverPhone || '-'}</span>
                            <CopyToClipboard
                              text={receiverPhone}
                              onCopy={() => {
                                notifySuccess(getText('WORD_PHONE_COPIED'))
                              }}
                            >
                              <SVGCopyIcon />
                            </CopyToClipboard>
                          </div>
                        )}
                        {receiverEmail && receiverName !== receiverEmail && (
                          <div className='customer_contact'>
                            {Boolean(receiver?.status?.email) &&
                              receiver?.status?.email?.state !== YELLOW && (
                                <Tooltip
                                  title={customerStatus?.text}
                                  destroyTooltipOnHide={true}
                                  trigger={['hover', 'click']}
                                  placement='right'
                                >
                                  <div className='customer_contact--icon'>
                                    {customerStatus?.icon}
                                  </div>
                                </Tooltip>
                              )}
                            <span>{receiverEmail}</span>
                            <CopyToClipboard
                              text={receiverEmail}
                              onCopy={() => {
                                notifySuccess(getText('WORD_EMAIL_COPIED'))
                              }}
                            >
                              <SVGCopyIcon />
                            </CopyToClipboard>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </Flex>
                <Flex align='center'>
                  <MessageAssignSelector
                    screenSize={screenSize}
                    messageType={messageType}
                    conversation={conversation}
                    removeConversationFromList={removeConversationFromList}
                  />
                  {screenSize !== 'desktop' && (
                    <React.Fragment>
                      {isSmallSize ? (
                        <SVGMaximizeMobileIcon
                          className={'show_right_panel'}
                          onClick={showRightBox}
                        />
                      ) : (
                        <SVGMaximizeIcon
                          className={'show_right_panel'}
                          onClick={showRightBox}
                        />
                      )}
                    </React.Fragment>
                  )}
                </Flex>
              </div>
            </div>
            <div className='message_page_center_panel_header--header--right'>
              {!conversation.isNew && (
                <MessageCenterHeaderCRMs
                  conversation={conversation}
                  addToList={addToList}
                  refreshConversation={refreshConversation}
                />
              )}
              <Flex align='center' style={{ marginLeft: 'auto' }}>
                <MessageCenterArchiveButton
                  conversation={conversation}
                  messageType={messageType}
                  removeConversationFromList={removeConversationFromList}
                />
                {messageType !== BROADCAST && !conversation.isNew && (
                  <CenterAISummerize
                    conversation={conversation}
                    aiSummaryMessage={aiSummary}
                  />
                )}
              </Flex>
            </div>
          </React.Fragment>
        )}
      </div>
      <MessageCenterInterest conversation={conversation} />
    </div>
  )
}

export default memo(MessageCenterHeader)
