import React, { memo } from 'react'
import { Tooltip } from 'antd'
import MessageInvitationModal from '../MessageInvitationModal/MessageInvitationModal'
import SVGSendInvitationIcon from '../../../../../icons/SVG/SVGSendInvitationIcon'
import { GBM, MESSENGER } from '../../../../../devOptions'
import { msgTooltipText } from '../../../utils/messageUtils'

const MessageInvitation = ({
  conversation,
  isLastMessageConsent,
  msgChangeEmailSMSType,
  isCustomerPhoneNumberValid,
  disabled,
  isCustomerRedStatus,
  isCustomerPhoneTemporaryBlocked,
  isCustomerYellowStatusAndRestricted,
  mobileDeskSize,
  onCloseMobileDesk,
}) => {
  return conversation &&
    conversation.receiver &&
    !conversation.unsubscribed &&
    conversation.kind !== GBM &&
    conversation.kind !== MESSENGER &&
    !isLastMessageConsent &&
    !conversation?.receiver?.status?.isWaitingAnswerToConsent ? (
    <MessageInvitationModal
      conversation={conversation}
      disabled={disabled}
      msgChangeEmailSMSType={msgChangeEmailSMSType}
      isCustomerRedStatus={isCustomerRedStatus}
      isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
      isCustomerPhoneNumberValid={
        isCustomerPhoneNumberValid || Boolean(isCustomerPhoneTemporaryBlocked)
      }
      mobileDeskSize={mobileDeskSize}
      onCloseMobileDesk={onCloseMobileDesk}
    />
  ) : (
    <Tooltip
      overlayStyle={{ zIndex: mobileDeskSize ? '-1000' : '10001' }}
      title={msgTooltipText(
        conversation,
        Boolean(
          isLastMessageConsent ||
            conversation?.receiver?.status?.isWaitingAnswerToConsent
        )
      )}
    >
      <div className='send-invitation-message icon-size disabled'>
        <span className='ant-btn-icon'>
          <SVGSendInvitationIcon />
        </span>
        {mobileDeskSize ? (
          <span>
            {msgTooltipText(
              conversation,
              Boolean(
                isLastMessageConsent ||
                  conversation?.receiver?.status?.isWaitingAnswerToConsent
              )
            )}
          </span>
        ) : (
          ''
        )}
      </div>
    </Tooltip>
  )
}

export default memo(MessageInvitation)
