import { getText } from './lang'

export const TABLE_DEFAULT_ROW_OPTIONS = [10, 25, 50, 100, getText('WORD_ALL')]
export const TABLE_DEFAULT_ROW_NUMBER = 12
export const TABLE_DEFAULT_ROW_HEIGHT = 48

export const SELECT_UNSELECTED_VALUE = 'none'
export const ADMIN = 'Admin'
export const MANAGER = 'Manager'
export const SUPERHUMAN = 'Superhuman'
export const SUPPORT_AGENT = 'Support agent'
export const SMS = 'sms'
export const EMAIL = 'email'

export const DEFAULT = 'default'
export const NIGHT = 'night'

export const IMMEDIATELY = 'immediately'
export const DAYS = 'days'
export const MINUTES = 'minutes'
export const HOURS = 'hours'

export const MOBILE = 'mobile'

export const YELLOW = 'yellow'
export const GREEN = 'green'
export const RED = 'red'

export const GBM = 'gbm'
export const MESSENGER = 'messenger'

// export const CONTACT_LIST_LIMIT = 50;

// export const NEXT_FIELD_KEYS = ["Tab", "Enter"];
export const NEXT_FIELD_KEYS = []

export const DEFAULT_IMAGE_UPLOAD_DIRECTORY = 'public/uploads'

export const SUPER_ADMIN_FAVORITE_EMAILS = [
  'mathieu@matador.ai',
  'nick@matador.ai',
  'nicolas@matador.ai',
  'serge@matador.ai',
  'harry@matador.ai',
  'paul@matador.ai',
  'kevin@matador.ai',
  'ayoub@matador.ai',
  'nadiia@matador.ai',
  'viktor@matador.ai',
  'narek@matador.ai',
  'vladyslav.patsuta@inoxoft.com',
  'mariia.kniazyk+1@inoxoft.com',
  'valentyna.rudkovska+1@inoxoft.com',
  'mykola.kopaihora@inoxoft.com',
  'mykola.kopaihora+10@inoxoft.com',
  'oleksandr.bondarenko@inoxoft.com',
  'grigor.nurijanyan@ubicross.pro',
  'grigor.nurijanyan+1@ubicross.pro',
  'davit.hovhannisyan@ubicross.pro',
  'narek@ubicross.pro',
  'davit.hovhannisyan@ubicross.pro',
  'hayrapetyanhr@gmail.com',
]
